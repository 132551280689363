import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './VerifyEmail.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { httpApi } from '@app/api/http.api';

export const VerifyEmailForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  // Getting the verification token, which should be provided in the URL search params
  const [queryParams] = useSearchParams();
  const token = queryParams.get('token');

  const handleSubmit = () => {
    setLoading(true);
    // Here, we'll call the API route to verify the email address
    httpApi
      .put('/users/verify', { token })
      .then(({ data }) => {
        console.log(data);
        notificationController.success({ message: t('Done! You can login to your account now') });
        setLoading(false);
        navigate('/auth/login');
      })
      .catch((err) => {
        setLoading(false);
        notificationController.error({
          message: err.message,
        });
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit}>
        <Auth.FormTitle>{t('Verify Email')}</Auth.FormTitle>
        <S.Description>{t('Click the button below to verify your email address')}</S.Description>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Verify Email')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
