import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { OrderModel } from '@app/domain/orders/OrderModel';
import { useNavigate } from 'react-router-dom';
import * as S from './RecentlyAddedOrderCard.styles';

interface RecentlyAddedOrderCardProps {
  orderItem: OrderModel;
}

export const RecentlyAddedOrderCard: React.FC<RecentlyAddedOrderCardProps> = ({ orderItem }) => {
  const { isTablet } = useResponsive();
  const navigate = useNavigate();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>@ {orderItem.location}</S.InfoText>
        <S.InfoText>{orderItem.description.slice(0, 110)}</S.InfoText>
      </S.InfoHeader>

      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.BidCrypto>{orderItem.team?.name}</S.BidCrypto>
        </S.CurrentBidWrapper>

        <S.CurrentBidWrapper>
          <S.Status>{orderItem.number}</S.Status>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>@ {orderItem.location}</S.InfoText>
      </S.InfoRow>

      <S.InfoRow>
        <S.CurrentBid>{orderItem.team?.name}</S.CurrentBid>
        <S.Status>{orderItem.number}</S.Status>
      </S.InfoRow>
    </>
  );

  return (
    <S.Card
      padding={0}
      $img={`/images/teams/${orderItem.team_id}.jpg`}
      onClick={() => navigate(`/orders/details/${orderItem.id}`)}
      style={{ cursor: 'pointer' }}
    >
      <S.OrderImage src={`/images/teams/${orderItem.team_id}.jpg`} alt="Image" />
      <S.OrderInfo>
        <S.InfoRow>
          <S.Title>{orderItem.order_status?.name}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.OrderInfo>
    </S.Card>
  );
};
