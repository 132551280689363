import { CategoryType } from '../categoriesList';

export interface Component {
  name: string;
  title: string;
  url: string;
  categories: CategoryType[];
  keywords: string[];
}

// TODO review and come up with a better approach for urls
// maybe we need to have enum with all routes like we had before?

// TODO change urls according to new dashboard routes and add new components
export const components: Component[] = [
  {
    name: 'Orders Dashboard',
    title: 'Orders Dashboard',
    url: `/`,
    categories: ['orders', 'dashboards'],
    keywords: ['dashboard', 'order', 'orders dashboard', 'painel', 'ordem', 'ordens'],
  },
  {
    name: 'Orders Table',
    title: 'Orders Table',
    url: `/orders/orders-table`,
    categories: ['orders'],
    keywords: ['order', 'orders table', 'data tables', 'ordem', 'ordens', 'tabela'],
  },
  {
    name: 'New Order',
    title: 'New Order',
    url: `/orders/new-order`,
    categories: ['orders'],
    keywords: ['order', 'new order', 'order form', 'ordem', 'ordens', 'nova'],
  },
];
