import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from '@app/components/common/Carousel/Carousel';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { ViewAll } from '@app/components/nft-dashboard/common/ViewAll/ViewAll';
import { RecentlyAddedOrderCard } from './order-card/RecentlyAddedOrderCard';
import { getRecentlyAddedOrders } from '@app/api/orderDashboard.api';
import { OrderModel } from '@app/domain/orders/OrderModel';
import { useResponsive } from '@app/hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import * as S from './RecentlyAddedOrder.styles';

interface RecentlyAddedOrderProps {
  orders?: OrderModel[];
}

export const RecentlyAddedOrder: React.FC<RecentlyAddedOrderProps> = ({ orders }) => {
  const [recentOrders, setRecentOrders] = useState<OrderModel[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mobileOnly, isTablet } = useResponsive();

  useEffect(() => {
    if (orders) setRecentOrders(orders);
    else
      getRecentlyAddedOrders().then((result) => {
        setRecentOrders(result);
      });
  }, [orders]);

  const cards = useMemo(() => {
    return {
      mobile: recentOrders.slice(0, 3).map((order) => <RecentlyAddedOrderCard key={order.id} orderItem={order} />),
      tablet: recentOrders.map((order) => (
        <div key={order.id}>
          <S.CardWrapper>
            <RecentlyAddedOrderCard orderItem={order} />
          </S.CardWrapper>
        </div>
      )),
    };
  }, [recentOrders]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>();

  // This will avoid showing duplicated elements when less than 3 files are present
  const slidesToShow = useMemo(() => {
    switch (recentOrders.length) {
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 3;
    }
  }, [recentOrders]);

  return (
    <>
      <NFTCardHeader title={t('Recently Updated Orders')}>
        {isTablet && (
          <Row align="middle">
            <Col>
              <ViewAll bordered={false} onClick={() => navigate('/orders/orders-table')} />
            </Col>

            <Col>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <LeftOutlined />
              </S.ArrowBtn>
            </Col>

            <Col>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                <RightOutlined />
              </S.ArrowBtn>
            </Col>
          </Row>
        )}
      </NFTCardHeader>

      <S.SectionWrapper>
        {mobileOnly && cards.mobile}

        {isTablet && recentOrders.length > 0 && (
          <Carousel
            ref={sliderRef}
            slidesToShow={slidesToShow}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: slidesToShow,
                },
              },
            ]}
          >
            {cards.tablet}
          </Carousel>
        )}
      </S.SectionWrapper>

      {mobileOnly && (
        <S.ViewAllWrapper>
          <ViewAll bordered={false} onClick={() => navigate('/orders/orders-table')} />
        </S.ViewAllWrapper>
      )}
    </>
  );
};
