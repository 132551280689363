import { OrderModel } from '@app/domain/orders/OrderModel';

export const getRecentlyAddedOrders = (): Promise<OrderModel[]> => {
  return new Promise((res) => {
    res([
      {
        id: 1,
        description: 'Culpa officia mollit ipsum laboris veniam in cupidatat veniam adipisicing do anim.',
        number: '45643',
        location: 'Room #1',
        opened_at: '2023-01-01T10:00:00-0300',
        executed_at: '2023-01-01T10:00:00-0300',
        finished_at: '2023-01-01T10:00:00-0300',
        purchase_request_cost: 0,
        purchase_request_cost_provided: 0,
        execution_cost: 0,
        execution_cost_provided: 0,
        purchase_request_file_provided: 0,
        order_file_provided: 0,
        purchase_request_need_id: 1,
        order_status_id: 1,
        order_status: {
          id: 1,
          name: 'Aberta',
        },
        team_id: 1,
        team: {
          id: 1,
          name: 'Alvenaria',
          description: '',
        },
      },
      {
        id: 2,
        description: 'Sit eiusmod consequat ipsum ut velit occaecat veniam sunt do duis nulla aute magna irure.',
        number: '48957',
        location: 'Room #3',
        opened_at: '2023-01-01T10:00:00-0300',
        executed_at: '2023-01-01T10:00:00-0300',
        finished_at: '2023-01-01T10:00:00-0300',
        purchase_request_cost: 0,
        purchase_request_cost_provided: 0,
        execution_cost: 0,
        execution_cost_provided: 0,
        purchase_request_file_provided: 0,
        order_file_provided: 0,
        purchase_request_need_id: 1,
        order_status_id: 2,
        order_status: {
          id: 2,
          name: 'Orçamento enviado',
        },
        team_id: 2,
        team: {
          id: 2,
          name: 'Hidrossanitária',
          description: '',
        },
      },
      {
        id: 3,
        description: 'Ullamco commodo et dolor reprehenderit.',
        number: '56784',
        location: 'Second Floor',
        opened_at: '2023-01-01T10:00:00-0300',
        executed_at: '2023-01-01T10:00:00-0300',
        finished_at: '2023-01-01T10:00:00-0300',
        purchase_request_cost: 0,
        purchase_request_cost_provided: 0,
        execution_cost: 0,
        execution_cost_provided: 0,
        purchase_request_file_provided: 0,
        order_file_provided: 0,
        purchase_request_need_id: 1,
        order_status_id: 3,
        order_status: {
          id: 3,
          name: 'Em Atendimento',
        },
        team_id: 3,
        team: {
          id: 3,
          name: 'Vidraçaria',
          description: '',
        },
      },
      {
        id: 4,
        description: 'Ullamco esse in consequat ea eiusmod sunt.',
        number: '42189',
        location: 'Main Hall',
        opened_at: '2023-01-01T10:00:00-0300',
        executed_at: '2023-01-01T10:00:00-0300',
        finished_at: '2023-01-01T10:00:00-0300',
        purchase_request_cost: 0,
        purchase_request_cost_provided: 0,
        execution_cost: 0,
        execution_cost_provided: 0,
        purchase_request_file_provided: 0,
        order_file_provided: 0,
        purchase_request_need_id: 1,
        order_status_id: 4,
        order_status: {
          id: 4,
          name: 'Executada',
        },
        team_id: 4,
        team: {
          id: 4,
          name: 'Chaveiro',
          description: '',
        },
      },
      {
        id: 5,
        description: 'Officia excepteur officia labore amet dolor esse ullamco anim culpa qui Lorem consequat.',
        number: '44579',
        location: 'Main Hall',
        opened_at: '2023-01-01T10:00:00-0300',
        executed_at: '2023-01-01T10:00:00-0300',
        finished_at: '2023-01-01T10:00:00-0300',
        purchase_request_cost: 0,
        purchase_request_cost_provided: 0,
        execution_cost: 0,
        execution_cost_provided: 0,
        purchase_request_file_provided: 0,
        order_file_provided: 0,
        purchase_request_need_id: 1,
        order_status_id: 5,
        order_status: {
          id: 5,
          name: 'Em Aprovação',
        },
        team_id: 5,
        team: {
          id: 5,
          name: 'Pintura',
          description: '',
        },
      },
    ]);
  });
};
