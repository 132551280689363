import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../common/Card/Card';
import { ExecutedChart } from './ExecutedChart';
import styled from 'styled-components';

interface ExecutedCardProps {
  items?: number[];
}

export const ExecutedCard: React.FC<ExecutedCardProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <ExecutedCardStyled id="activity" title={t('Orders Executed this Week')} padding={0}>
      <ExecutedChart data={items ? items : [3, 14, 35, 22, 34, 20, 15]} />
    </ExecutedCardStyled>
  );
};

const ExecutedCardStyled = styled(Card)`
  height: 100%;
`;
