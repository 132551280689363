import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RecentlyAddedOrder } from '@app/components/order-dashboard/recently-added/RecentlyAddedOrder';
import { ExecutedCard } from '@app/components/order-dashboard/executedCard/ExecutedCard';
import { StatusCard } from '@app/components/order-dashboard/statusCard/StatusCard';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { OrderDashboardModel } from '@app/domain/orders/OrderDashboardModel';
import { statusChartData } from '@app/constants/statusChartData';

const OrdersDashboardPage: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();

  const [orderDashboard, setOrderDashboard] = useState<OrderDashboardModel>({
    orders_by_status: statusChartData,
    recent_orders: [],
    week_executed_orders: [0, 0, 0, 0, 0, 0, 0],
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetching the API
    httpApi
      .get(`/orders/dashboard`)
      .then(({ data }) => {
        setOrderDashboard(data.data);
      })
      .catch((err) => {
        if (err.options.status === 401) {
          notificationController.error({ message: err.message });
          navigate('/auth/lock');
        }
      });
  }, [navigate]);

  const desktopLayout = (
    <Row gutter={[60, 60]}>
      <Col span={24}>
        <RecentlyAddedOrder orders={orderDashboard.recent_orders} />
      </Col>

      <Col id="activity" xl={24} xxl={12}>
        <ExecutedCard items={orderDashboard.week_executed_orders} />
      </Col>

      <Col id="health" xl={24} xxl={12}>
        <StatusCard data={orderDashboard.orders_by_status} />
      </Col>
    </Row>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <RecentlyAddedOrder orders={orderDashboard.recent_orders} />
      </Col>

      <Col id="activity" xs={24} md={12} order={(isTablet && 8) || 0}>
        <ExecutedCard items={orderDashboard.week_executed_orders} />
      </Col>

      <Col id="health" xs={24} md={12} order={(isTablet && 9) || 0}>
        <StatusCard data={orderDashboard.orders_by_status} />
      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle>{t('Orders Dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default OrdersDashboardPage;
