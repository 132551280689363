export interface OrderStatusProps {
  value: number;
  name: string;
  description: string;
}

export const statusChartData: OrderStatusProps[] = [
  {
    value: 40,
    name: 'Aberta',
    description: 'Ordens abertas, que ainda não foram avaliadas',
  },
  {
    value: 20,
    name: 'Finalizada',
    description: 'Ordens que já foram finalizadas, medidas ou que foram canceladas',
  },
  {
    value: 18,
    name: 'Em atendimento',
    description: 'Ordens que estão sendo executadas',
  },
  {
    value: 12,
    name: 'Executada',
    description: 'Ordens que já foram executadas',
  },
  {
    value: 10,
    name: 'Em aprovação',
    description: 'Ordens cujo orçamento foi enviado, ou cuja execução está em aprovação',
  },
];
