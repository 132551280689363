import React from 'react';
import { DashboardOutlined, ToolOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Orders Dashboard',
    key: 'orders-dashboard',
    // TODO use path variable
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Orders',
    key: 'orders',
    icon: <ToolOutlined />,
    children: [
      {
        title: 'Orders Table',
        key: 'orders-table',
        url: '/orders/orders-table',
      },
      {
        title: 'New Order',
        key: 'new-order',
        url: '/orders/new-order',
      },
    ],
  },
];
