export type CategoryType = 'dashboards' | 'orders';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'dashboards',
    title: 'Dashboards',
  },
  {
    name: 'orders',
    title: 'Orders',
  },
];
